<template>
  <button type="button" class="mb-4 flex items-center gap-x-2" :disabled="disabled" @click="handleRouterLinkClick">
    <span
      class="relative block h-10 w-10 rounded-heavy bg-white text-4xl transition-colors duration-300 hover:bg-brick-100">
      <SvgoChevronRight class="absolute bottom-0 left-0 right-0 top-0 mx-auto my-0 h-full w-4 rotate-180 md:w-6" />
    </span>
    <span v-if="label" class="text-xs font-medium text-gray-700">
      {{ label }}
    </span>
  </button>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from "vue-router";

const props = withDefaults(
  defineProps<{
    defaultTo?: RouteLocationRaw;
    label?: string;
  }>(),
  {
    defaultTo: "/fachrichtungen",
  },
);

onUpdated(() => {
  disabled.value = false;
});

const disabled = ref(false);

const route = useRoute();
const handleRouterLinkClick = async () => {
  disabled.value = true;

  if (route.meta.freshInstance) {
    await navigateTo(props.defaultTo);
    disabled.value = false;
  } else {
    useRouter().back();
  }
};
</script>

<style scoped></style>
